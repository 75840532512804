import * as React from "react";

import { Row, Col } from "reactstrap";
import DefaultLayout from "../layouts/default";
import { SinglePostWrap } from "../components/LayoutComponents.stc";
import { graphql } from "gatsby";
import { ArtType, FamilieType } from "../components/types";
import _ from "lodash";

import { StyledLink, ArticleImage, ArticleMeta } from "../components/LayoutComponents.stc";
import { Helmet } from "react-helmet";

export const query = graphql`
query CategoryPageQUery($slug: [String], $catIds: [CMS_QueryArgument]) {
  cms {
    arten: entries(section: "arten", relatedTo: $catIds) {
      ... on CMS_arten_arten_Entry {
        id
        title
        nameDeutsch
        slug
        bilder(limit: 1) {
          id
          url
        }
      }
    }
    category: category(slug: $slug) {
      ... on CMS_ordnungFamilien_Category {
        id
        title
        slug
        level
        children {
          id
          title
          slug
          ... on CMS_ordnungFamilien_Category{
            bild
            {
              url
            }
          }
          
        }
        beschreibung
        bild {
          url
        }
      }
    }
  }
}
`;

interface CategoryProps {
  pageContext: {
    slug: string;
  };
  data: {
    cms: {
      category: FamilieType;
      arten: ArtType[];
    };
  };
}

const Category: React.FC<CategoryProps> = (props) => {
  const {
    pageContext,
    data: {
      cms: {
        category: { title, beschreibung, children },
        arten,
      },
    },
  } = props;



  let catArten;
  let h2Title;
  let sliderItems:any = [];

  if(!_.isEmpty(children) && !_.isUndefined(children)){
    h2Title = "Unterkategorien";




    catArten = children.map((childCategory) => {
      childCategory.bild && sliderItems.push(
        {
          src: childCategory.bild[0].url + "?w=1500",
          altText: childCategory.title,
        }
      )

      return (<Col key={childCategory.slug} xs="12" sm="6" md="4">
        <StyledLink to={"/kategorie/" + pageContext.slug + "/" + childCategory.slug}>
          <ArticleImage src={childCategory.bild[0].url + "?w=530"} />
          <ArticleMeta>
            {childCategory.title}
          </ArticleMeta>
        </StyledLink>
      </Col>)
    })
  }else{
    h2Title = "Arten";
    catArten = arten?.map((art) => {
         const {title, slug, bilder, nameDeutsch} = art;
        {bilder[0]?.url && (
          sliderItems.push(
            {
              src: bilder[0].url + "?w=1500",
              altText: art.title,
            }
          )
          )}

         return (
         <Col key={slug} xs="12" sm="6" md="4">
        <StyledLink to={"/art/"+slug}>
          {bilder[0]?.url && (
            <ArticleImage src={bilder[0].url + "?w=530"} />
          )}
          <ArticleMeta>
            {title!} {nameDeutsch && (<>({nameDeutsch})</>)}
          </ArticleMeta>
        </StyledLink>
      </Col>)
       })
  }


  let description = '';
  let pageTitle = title!;

  if(beschreibung){
    description = beschreibung;
  }

  pageTitle = pageTitle + " - Insects";
  
  return (
    <DefaultLayout sliderItems={sliderItems}>
    <Helmet
          title={pageTitle}
          meta={[
            {
              name: "description",
              content: description,
            },
          ]}
    ></Helmet>
    <SinglePostWrap>
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: beschreibung! }}></div>
    </SinglePostWrap>
    <h2>{h2Title}</h2>
          <Row>
          {catArten}
      </Row>

    </DefaultLayout>
  );
};

export default Category;
